<template>
  <wptv-menu class="user-menu">
    <wptv-menu-item
      :aria-label="$t('general.menu.myVideos')"
      :selected="$route.name === RouteUtils.ROUTE.VIDEOS">
      <router-link
        v-tealium:click="$stats.menu.onClickMyVideos()"
        :to="{ name: RouteUtils.ROUTE.VIDEOS }"
        :aria-label="$t('general.menu.accessibility.item', { title: $t('general.menu.myVideos') })"
        class="nav-link"
        data-wptv="menu-mes_videos">
        {{ $t('general.menu.myVideos') }}
      </router-link>
    </wptv-menu-item>
    <wptv-menu-item
      :aria-label="$t('general.menu.myBookmarksAlerts')"
      :selected="$route.name === RouteUtils.ROUTE.LIST">
      <router-link
        v-tealium:click="$stats.menu.onClickMyBookmarksAlerts()"
        :aria-label="$t('general.menu.accessibility.item', { title: $t('general.menu.myBookmarksAlerts') })"
        :to="{ name: RouteUtils.ROUTE.LIST }"
        class="nav-link"
        data-wptv="menu-mes_favoris">
        {{ $t('general.menu.myBookmarksAlerts') }}
        <notification
          :show="counter > 0"
          :notification-menu="true" />
      </router-link>
    </wptv-menu-item>
    <wptv-menu-item class="dropdown-item">
      <wptv-dropdown
        ref="dropVodSpace"
        v-tealium:click="$stats.menu.onClickHelp()"
        :label="$t('general.menu.vodLinksTitle')"
        :items="vodSpace"
        class="nav-dropdown-menu"
        align="right"
        data-wptv="help-dropdown"
        @input="selectFromDropDown" />
    </wptv-menu-item>
  </wptv-menu>
</template>

<script>
import { WptvDropdown } from '@wptv/components/ui/dropdown'
import { WptvMenu, WptvMenuItem } from '@wptv/components/ui/menu'
import Notification from '../../../../shared/notification/notification'
import RouteUtils from '../../../../../route.utils'
import { mapGetters } from 'vuex'
import { launchOrDownloadApp } from '../../../../../plugins/appMobile/appMobile.client'

export default {
  name: 'UserMenu',
  components: {
    WptvMenu,
    WptvMenuItem,
    WptvDropdown,
    Notification
  },
  data () {
    return {
      RouteUtils
    }
  },
  computed: {
    ...mapGetters({
      profile: 'core/getProfile',
      isPc: 'useragent/isPC',
      isCompatibleMobile: 'useragent/isCompatibleMobile',
      osName: 'useragent/getOsName',
      counter: 'alerts/getCounterNotification'
    }),
    vodSpace () {
      const vodSpaceItems = []
      vodSpaceItems.push({
        label: this.$t('general.menu.myAccount'),
        stat: this.$stats.menu.onClickMyAccount()
      })

      // only for pc
      if (this.isPc) {
        vodSpaceItems.push({
          label: this.$t('general.menu.lecteurVod'),
          stat: this.$stats.menu.onClickMlv()
        })
      }
      // only for mobile
      if (this.isCompatibleMobile) {
        vodSpaceItems.push({
          label: this.$t('general.menu.appliTvOrange'),
          stat: this.$stats.menu.onClickAppliTv()
        })
      }

      // those options go last
      vodSpaceItems.push({
        label: this.$t('general.menu.needHelp'),
        stat: this.$stats.menu.onClickAssistance(this.$config.confetti.public.urls.support.vodAssistance),
        link: this.$config.confetti.public.urls.support.vodAssistance
      },
      {
        label: this.$t('general.menu.configuration'),
        stat: this.$stats.menu.onClickConfigTests()
      })

      return vodSpaceItems
    }
  },
  mounted () {
    this.$refs.dropVodSpace.resetValue()
  },
  methods: {
    /**
     * click on item from dropdown
     * @param item
     */
    selectFromDropDown (item) {
      this.$refs.dropVodSpace.resetValue()
      this.sendStats(item.stat)

      switch (item.label) {
        case this.$t('general.menu.myAccount'):
          this.$router.push({ name: RouteUtils.ROUTE.ACCOUNT })
          break

        case this.$t('general.menu.configuration'):
          this.$root.$emit('open-explicit-tests')
          break

        case this.$t('general.menu.lecteurVod'):
        {
          const url = this.osName === 'Windows' ? this.$config.confetti.public.urls.footerDownloadMlvWin : this.$config.confetti.public.urls.footerDownloadMlvMac
          window.location.assign(url)
          break
        }
        case this.$t('general.menu.needHelp'):
          window.open(item.link, '_blank').focus()
          break

        case this.$t('general.menu.appliTvOrange'):
        {
          const appMobileParams = {
            value: {
              osName: this.osName,
              pageName: this.$route.name,
              pageArgs: this.$route.params
            }
          }
          launchOrDownloadApp(appMobileParams, this.$config)
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown-item {
  display: flex;
  width: auto;
  flex-shrink: initial;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 1.25rem;
}

::v-deep .nav-dropdown-menu .o-link-arrow {
  @include mediaQueries('font-size', (xlarge: 1.125rem));
  @include mediaQueries('padding-bottom', (large: 0.9rem));
  @include mediaQueries('line-height', (medium: 1));
}
</style>
